import "./App.css";
import NavBar from "./components/navBar.jsx";
import Main from "./components/Main.jsx";

function App() {
  return (
    <div style={{ height: "100vh" }} className="App">
      <div style={{ zIndex: 1, position: "absolute", width: "100%" }}>
        <NavBar />
      </div>
      <Main />
    </div>
  );
}

export default App;
