import React from "react";
import FixedScreen from "../components/fixedScreen";
import "../css/portal.css";

const Portal = () => {
  return (
    <FixedScreen>
      <div className="center_container">
        <section className="main_container">
          <div
            className="ccard"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img className="homeimg personalAJ" src="headshot.png" alt="" />
            <article className="content">
              <h3 className="headers3">Personal Website</h3>
              <p className="paragraph_text">You're Here! Click Me.</p>
            </article>
          </div>

          <div
            className="ccard"
            onClick={() => {
              window.location = "https://networkvice.com/";
            }}
          >
            <img className="homeimg" src="cloud_service.png" alt="" />
            <article className="content">
              <h3 className="headers3">Personal Drive</h3>
              <p className="paragraph_text">
                A personal cloud-storage network for minimizing CPU harddrive
                space.
              </p>
            </article>
          </div>

          <div
            className="ccard"
            onClick={() => {
              window.location = "https://kaleau.com/";
            }}
          >
            <img
              className="homeimg"
              src="KAL_Asset5_CRCL_TRNS_9400.png"
              alt=""
            />
            <article className="content">
              <h3 className="headers3">Kaleau</h3>
              <p className="paragraph_text">
                Take a look into the App I'm currently working on.
              </p>
            </article>
          </div>

          <div
            className="ccard"
            onClick={() => {
              window.location = "https://albawabthoughts.com";
            }}
          >
            <img className="homeimg" src="albawab_thoughts.png" alt="" />
            <article className="content">
              <h3 className="headers3">Personal Blog</h3>
              <p className="paragraph_text">A simple / self hosted PHP blog</p>
            </article>
          </div>
        </section>
      </div>
    </FixedScreen>
  );
};

export default Portal;
